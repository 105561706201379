<template>
  <div class="px-2" id="agendaView">
    <!-- Content -->
    <div class="flex flex-row">
      <!-- Hours -->
      <div class="w-8">
        <div
          class="py-2 px-1 text-center font-bold uppercase text-black"
          style="height: 93px"
        ></div>
        <div
          v-for="(hour, ii) in getDayHours()"
          :key="ii"
          class="text-center font-bold text-dark h-100 hour-week relative"
        >
          <span>
            {{ _moment()(hour, "HH:mm").format("H") }}
          </span>
        </div>
      </div>
      <div class="w-full relative">
        <div class="grid grid-rows-7 grid-cols-7 grid-flow-col gap-0">
          <!-- Days -->
          <div
            v-for="(day, indexDay) in getDaysWeek()"
            :key="indexDay"
            id="daysInWeek"
          >
            <div
              class="
                flex flex-col
                justify-center
                items-center
                gap-y-2
                py-5
                sticky
                z-9
                bg-white
                border-0 border-b border-solid border-gray-300 border-extra-top
              "
              :style="`top: ${pixel.top}px;`"
            >
              <span class="font-EffraR text-lg text-gray-600 capitalize">{{
                _moment()(day.date, "DD/MM/YYYY").format("ddd")
              }}</span>
              <span
                class="
                  h-8
                  w-8
                  flex
                  items-center
                  justify-center
                  rounded-full
                  font-EffraM
                  text-xl
                "
                :class="
                  _moment()(day.date, 'DD/MM/YYYY').isSame(_moment()(), 'day')
                    ? `bg-dokBlue-ultra text-white`
                    : `bg-white text-blackdok`
                "
                >{{ _moment()(day.date, "DD/MM/YYYY").format("DD") }}</span
              >
            </div>
            <div
              v-for="(hour, ii) in getDayHours()"
              :key="ii"
              class="
                border-0 border-b border-l border-solid border-gray-300
                cursor-pointer
                text-center
                uppercase
                h-100
                relative
                border-extra-left
              "
              :class="
                _moment()(day.date, 'DD/MM/YYYY').isSame(_moment()(), 'day')
                  ? 'bg-gray-100 '
                  : ' '
              "
              :aria-label="`${day.date} ${hour}`"
            >
              <div
                v-for="mintues in getMintuesinHours(hour)"
                :key="mintues.hour"
                :title="`${day.date} ${mintues.hour}`"
              >
                <div
                  v-if="eventsDay(day.date, mintues.hour).length && evetnShow"
                  :style="`height: ${(100 / 60) * 15}px`"
                  class="z-8 relative"
                >
                  <itemEvent
                    class="flex items-start justify-between w-full px-1"
                    v-for="eventItem in eventsDay(day.date, mintues.hour)"
                    :key="eventItem.id"
                    :event="eventItem"
                    :start-drag="startDrag"
                    :class="
                      eventItem.type === 'RESERVED-SLOT' ||
                      _moment()(eventItem.startAt).isBefore(new Date())
                        ? 'cursor-default'
                        : 'cursor-move'
                    "
                    >{{ eventItem }}</itemEvent
                  >
                </div>
                <div
                  :style="`height: ${(100 / 60) * 15}px`"
                  class="
                    hover:bg-gray-300
                    z-1
                    flex
                    justify-center
                    items-center
                    group
                  "
                  v-if="!eventsDay(day.date, mintues.hour).length"
                  @click="onNewEvent(`${day.date} ${mintues.hour}`)"
                  @drop="onDrop($event, `${day.date} ${mintues.hour}`)"
                  @dragenter="dragEnter"
                  @dragleave="dragLeave"
                  @dragover.prevent
                >
                  <span
                    class="
                      font-EffraR
                      hidden
                      group-hover:block
                      text-dokBlue-ultra
                    "
                    >{{ mintues.hour }}</span
                  >
                </div>
              </div>
              <!-- Blocked Hour -->
              <div
                v-if="!blockedHours(`${day.date} ${hour}`, indexDay).length"
                @click="
                  !_moment()(
                    `${day.date} ${hour}`,
                    'DD/MM/YYYY HH:mm'
                  ).isBefore(new Date(), 'hour')
                    ? onNewEvent(`${day.date} 08:00`)
                    : null
                "
                class="bg-isblock bg-opacity-60 absolute bottom-0 top-0 w-full"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.border-extra-top {
  @apply relative;
  &:after {
    height: 0;
    width: 1px;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top: 30px solid #cbd5e0;
  }
}

.hour-week {
  @apply font-EffraR text-gray-600 relative;
  span {
    position: absolute;
    right: 22px;
    top: -10px;
  }
}
</style>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { find, map, filter } from "lodash";

// Components
const itemEvent = () => import("./items/index");

export default {
  props: {
    events: Array,
    onNewEvent: Function,
    nowDate: Object
  },
  data() {
    return {
      hourStart: moment("08:00", "HH:mm").format("HH:mm"),
      hourNow: moment().format("HH:mm:s"),
      evetnShow: false,
      pixel: {
        top: 0,
        start: false
      }
    };
  },
  computed: {
    ...mapGetters({
      slots: "agenda/slots"
    })
  },
  mounted() {
    this.evetnShow = true;
    EventBus.$on("nextWeek", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    ...mapActions("agenda", ["UPDATE_DATE_EVENT"]),
    scrollNavWeek() {
      let scrollTop = document.getElementById("contentProDok").scrollTop;

      if (scrollTop > 0) this.pixel.start = true;
      else this.pixel.start = false;
    },
    linePixel() {
      let x = moment(this.hourNow, "HH:mm").diff(
        moment(this.hourStart, "HH:mm"),
        "minutes"
      );

      let y = 148 / 60;

      return Math.ceil(x * y + 58);
    },
    blockedHours(date, indexDay) {
      let formatDateToMoment = moment(date, "DD/MM/YYYY HH:mm");
      let slotsByIndexDay = find(this.slots, slot => slot.day === indexDay + 1);

      if (!slotsByIndexDay) return false;

      let isBlocked = filter(slotsByIndexDay.slots, slot => {
        const from = moment(slot.from, "HH:mm"),
          to = moment(slot.to, "HH:mm");

        if (formatDateToMoment.isBefore(new Date(), "day")) return;

        return (
          moment(formatDateToMoment.format("HH:mm"), "HH:mm").isBetween(
            from,
            to
          ) || moment(formatDateToMoment.format("HH:mm"), "HH:mm").isSame(from)
        );
      });

      return isBlocked;
    },
    dragEnter(ev) {
      ev.target.classList.add("bg-gray-400");
    },
    dragLeave(ev) {
      ev.target.classList.remove("bg-gray-400");
    },
    startDrag(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("fromDate", item.startAt);
      event.dataTransfer.setData("eventId", item._id);
    },
    async onDrop(event, toDate) {
      const fromDate = moment(
          event.dataTransfer.getData("fromDate"),
          "DD/MM/YYYY HH:mm"
        ),
        eventId = event.dataTransfer.getData("eventId"),
        toDateFormat = moment(toDate, "DD/MM/YYYY HH:mm");

      if (toDateFormat.isBefore(new Date(), "hour")) {
        this.$vs.notify({
          color: "danger",
          text: "Un événement ne peut pas être déplacé vers une ancienne date"
        });

        return event.target.classList.remove("bg-gray-400");
      }

      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            this.$vs.loading();
            const data = await this.UPDATE_DATE_EVENT({
              id: eventId,
              toDate: toDateFormat
            });

            if (data.ok) {
              let item = find(this.events, ievent => ievent._id === eventId);
              item.startAt = toDateFormat;
              this.$vs.loading.close();
            }
          }
        }
      });

      event.target.classList.remove("bg-gray-400");
    },
    getMintuesinHours(hour) {
      let mintues = [];
      Array(4)
        .fill()
        .filter((x, i) => {
          mintues.push({
            hour: moment(`${hour}:00`, "HH:mm")
              .add(15 * i, "m")
              .format("HH:mm")
          });
        });

      return mintues;
    },
    getDayHours() {
      let items = [];
      new Array(16).fill().forEach((acc, index) => {
        items.push(moment({ hour: index + 8 }).format("HH:mm"));
      });
      return items;
    },
    getDaysWeek() {
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push({
          date: moment(this.nowDate)
            .day("Lundi")
            .add(i, "days")
            .format("DD/MM/YYYY"),
          label: moment(this.nowDate)
            .day("Lundi")
            .add(i, "days")
            .format("dddd")
        });
      }

      return days;
    },
    eventsDay(day, hour) {
      let dateDay = moment(`${day} ${hour}`, "DD/MM/YYYY HH:mm");

      let filterEvents = [];

      map(this.events, event => {
        let dateEvent = moment(event.startAt);

        if (
          dateEvent.isSame(dateDay, "day") &&
          dateEvent.format("HH:mm") === dateDay.format("HH:mm")
        ) {
          filterEvents.push(event);
        }
      });

      return filterEvents;
    },
    _moment() {
      return moment;
    }
  },
  components: { itemEvent }
};
</script>
